<template>
  <v-container>
    <h1>2021.08.08</h1>
    <div>
      <ul>
        <li>Volume slider (replaces the old mute-button)</li>
        <li>New "Settings" view (in the main menu)</li>
        <li>New setting to change how many buttons are shown per row</li>
        <li>"Movie" renamed to "Category"</li>
        <li>
          <strong>[Fixed]</strong> "Favourite" button in "list" view is now
          working
        </li>
        <li><strong>[Fixed]</strong> Sorting by "date" is now working</li>
      </ul>
    </div>
    <h1>2021.03.01</h1>
    <div>No changes tracked yet</div>
  </v-container>
</template>

<script>
export default {
  name: "Changelog",
  methods: {},
  computed: {},
};
</script>
